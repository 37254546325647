import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'

import { normalizeBlockContentNodes } from '@blocks-helpers'
import Blog3 from '@solid-ui-blocks/Blog/Block03'
import httpService from '../../service/httpService';
import loadsGif from '../assets/loads.gif'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'
import Maliyet from '@solid-ui-blocks/Maliyet/Route'

const KaliteYonetim = props => {


  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  const [indId, setindId] = useState("");
  const [teklifId, setteklifId] = useState("");

  const [ldg, setLdg] = useState(false);


  useState(() => {
    if (typeof window !== 'undefined') {

     if (window.location.search.includes("I="))
     {
      setindId(window.location.search.replace("?I=", ""));
     }else if(window.location.search.includes("T="))
    {
      setteklifId(window.location.search.replace("?T=", ""));
    } else {
      window.location.assign("/");
    }
  
     // TeklifDoldur(window.location.search.replace("?I=", ""));

      setLdg(true);
    }
  }, []);



  if (indId == "0") return (<></>);
  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Maliyet Hesaplama ve Tekliflendirme' />
      <Divider space='5' />
      {ldg == false ? <div className='dv-l'>
        <Divider space="3" />
        <img src={loadsGif} alt="online box die cutting loading" style={{ width: "200px", objectFit: "contain" }} />
        <Divider space="5" />
      </div> :
        // <Blog3 id={`blgbicak`} content={{ Id: "53" }} benzerModelList={{ benzerModelList: benzerModelList }} />
        <Maliyet content={{ IndirmeId: indId,TeklifId:teklifId }} ></Maliyet>
      }
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageKaliteYonetim { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`


export default KaliteYonetim
